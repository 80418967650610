/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('secure-inbox', {
    parent: 'root',
    url: '/secure-inbox',
    templateUrl: 'apps/secure-inbox/templates/acp-secure-inbox.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.apps.secure-inbox" */ 'apps/secure-inbox').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
